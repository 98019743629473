

import Vue from "vue"
import axios from "axios"
import { EventBus } from "@/plugins/eventBus"
import ValidatedWorkerList from "../ValidatedWorkerList.vue"


interface ItemSelect {
    value: string,
    text: string
}

interface OrderData {
    id: number,
    quantity: number,
    total_arrived: number,
    periods: PeriodData[]
}

interface PeriodData {
    id: number,
    started_at: string,
    finished_at: string
}

export default Vue.extend({
    name: 'CloseOrderWorkerModal',
    components: {
      ValidatedWorkerList
    },
    props: {
        order: {
            type: Object,
        },
        showCloseModal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            closeOrderStateItems: [] as ItemSelect[],
            selectedCloseOrderState: null as string|null,
            confirmationCloseModal: false,
            closingReasons: null as string|null,
            showInputClosingReasons: false,
            showModal: false,
            reloadOrderWorkerPeriod: 0
        }
    },
    mounted() {
        this.showModal = this.showCloseModal
        this.closeOrderStateItems = []
        if ((this.order as OrderData).periods && this.order.periods.length > 0) {
            let testingDate = new Date(this.order.periods[this.order.periods.length-1].started_at) < new Date()
            if (this.order.total_arrived === (this.order.quantity * this.order.periods?.length) && testingDate) {
                this.closeOrderStateItems.push({value: "honored", text: this.$vuetify.lang.t("$vuetify.honored")})
            }
            if (this.order.total_arrived === 0 && testingDate) {
                this.closeOrderStateItems.push({value: "not_honored", text: this.$vuetify.lang.t("$vuetify.not_honored")})
            }
            if (this.order.total_arrived > 0 && this.order.total_arrived < (this.order.quantity * this.order.periods?.length) && testingDate) {
                this.closeOrderStateItems.push({value: "partially_honored", text: this.$vuetify.lang.t("$vuetify.partially_honored")})
            }
        }
    },
    watch:{
        showCloseModal() {
            this.showModal = this.showCloseModal
        },
        selectedCloseOrderState() {
            if (['not_honored', "partially_honored"].includes(this.selectedCloseOrderState as string)) {
                this.showInputClosingReasons = true;
            } 
            if (this.selectedCloseOrderState === 'honored') {
                this.showInputClosingReasons = false;
            }
        }
    },
    methods: {
        openConfirmation() {
            let testDisabled = true
            if (this.selectedCloseOrderState !== "honored") {
                // if != honored => require data for closing reasons
                testDisabled = !(this.closingReasons === null || this.closingReasons.length === 0)
            }
            this.confirmationCloseModal = (this.selectedCloseOrderState !== null && testDisabled)
        },
        resetCloseOrder(){
            this.selectedCloseOrderState = null
            this.confirmationCloseModal = false
            this.closingReasons = null
            this.showModal = false
            this.$emit('close')
        },
        closeOrderProcess() {
            // building body to update order
            let body = {} as {state: string, status: string, closing_reasons?: string}
            body.status = "closed"
            body.state = this.selectedCloseOrderState as string
            if (["not_honored", "partially_honored"].includes(this.selectedCloseOrderState as string)) {
                body.closing_reasons = this.closingReasons as string
            }
            // update order
            let message =  this.$vuetify.lang.t('$vuetify.closing_success');
            let color = "success";
            axios.put(`/v1/orders`, {[this.order.id]: body})
            .then(()=> {
                this.$emit('update')
            }).catch(() => {
                message = this.$vuetify.lang.t('$vuetify.closing_error')
                color = 'error'
            }).finally(()=>{
                this.resetCloseOrder()
                EventBus.$emit('snackbar', { message, color})
            })
        },
    }
})
